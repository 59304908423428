import React from "react"
import TextError from "./TextError"
import { Field, ErrorMessage } from "formik"
import * as styles from "../css/formik.module.css"

const TextArea = props => {
  const { label, name, className, ...rest } = props
  return (
    <div className={styles[`${className}`]}>
      <label htmlFor={name}>
        {label}
      </label>
      <Field
        className={styles.textArea}
        as="textarea"
        id={name}
        name={name}
        {...rest}
      />
      <ErrorMessage name={name} component={TextError} />
    </div>
  )
}

export default TextArea
