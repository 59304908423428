import React, { useState } from "react"
import { Formik, Form } from "formik"
import { v4 } from "uuid"
import API from "../../services/api"
import FormikControl from "../../formik/FormikControl"
import * as Yup from "yup"
import { cloneDeep, isEmpty } from "lodash"
import { useQuery, useMutation } from "@apollo/client"
import getCategories from "../../query/getCategories"
import {
  AddCustomFieldToPostMutation,
  CreateDFXModelMutation,
} from "../../mutations/createDFXModel"
import * as styles from "../../css/formik.module.css"

const CreateDFXModelForm = () => {
  const [mediaUploadData, setmediaUploadData] = useState({
    thumbnail: "",
    glb_file: "",
  })

  const [customFields, setCustomFields] = useState(null)

  const [dfxModelTitle, setDfxModelTitle] = useState("")

  const [dfxModelID, setDfxModelID] = useState(null)
  const [categories, setCategories] = useState([])

  const initialValues = {
    id: "",
    sku: "",
    type: "",
    category: "",
    price: "",
    title: "",
    prefab: "",
    description: "",
    files: {
      thumbnail: "",
      glb_file: "",
    },
  }

  const validationSchema = Yup.object({
    id: Yup.number()
      .required("ID is required")
      .positive()
      .integer("Must be Positive Integer"),
    sku: Yup.number()
      .required("SKU is required")
      .positive()
      .integer("Must be Positive Integer"),
    type: Yup.string().required("Required"),
    price: Yup.number()
      .required("Required")
      .positive()
      .integer("Price must be a positive integer"),
    title: Yup.string().required("Required"),
    prefab: Yup.string().required("Required"),
    thumbnail: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    glb_file: Yup.string().required("Required"),
  })

  const saveMediaUploadId = async files => {
    const formData = new FormData()
    for (const file in files) {
      formData.append("file", files[file], files[file].name)
      const { data } = await API.post("wp/v2/media", formData)
      console.log(data)
      setmediaUploadData(prevState => ({
        ...prevState,
        [file]: data.id,
      }))
    }

    return true
  }

  let catArray = []

  const { loading: categoryLoading } = useQuery(getCategories, {
    onCompleted: data => {
      // console.log(data.categories.nodes)

      data.categories.nodes.map(category => {
        // console.log(category)
        // console.log(category.parent)
        if (category.parent) {
          if (category.parent.node.name === "DFX Model") {
            catArray.push({
              key: category.name,
              value: category.databaseId,
            })
          }
        }
      })

      // console.log(catArray)
    },
    onError: error => {
      console.log(error)
    },
  })

  // cat()

  const [createDFXModel, { data: dfxModelData, loading: dfxModelLoading }] =
    useMutation(CreateDFXModelMutation, {
      variables: {
        createDfxModelInput: {
          clientMutationId: v4(),
          title: dfxModelTitle,
          status: "PUBLISH",
        },
      },
      onCompleted: data => {
        const { createDFXModel } = data
        const dfxModelId = createDFXModel.dFXModel.databaseId
        setDfxModelID(dfxModelId)
        createDFXField()
      },
      onError: error => {
        if (error) {
          if (!isEmpty(error)) {
            console.log(error)
          }
        }
      },
    })

  const [createDFXField, { data: dfxFieldData, loading: dfxFieldLoading }] =
    useMutation(AddCustomFieldToPostMutation, {
      variables: {
        addCustomFieldToPostInput: {
          clientMutationId: v4(),
          ...customFields,
          ...mediaUploadData,
          post_database_id: dfxModelID,
        },
      },
      onCompleted: data => {
        console.log(data)
      },
      onError: error => {
        if (error) {
          if (!isEmpty(error)) {
            console.log(error)
          }
        }
      },
    })

  const onSubmit = async (values, submitProps) => {
    console.log("Form data", values)
    const isFileSent = await saveMediaUploadId(values.files)
    if (isFileSent) {
      const valueToSend = cloneDeep(values)
      delete valueToSend.files
      setCustomFields(valueToSend)
      setDfxModelTitle(values.title)
      createDFXModel()
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={validationSchema}
      onSubmit={onSubmit}
      // enableReinitialize={false}
    >
      {formik => {
        // console.log("Formik props", formik)
        return (
          <Form className={styles.formikContainer}>
            <FormikControl
              control="input"
              type="number"
              label="Id"
              name="id"
              className="id"
            />
            <FormikControl
              control="input"
              type="number"
              label="SKU"
              name="sku"
              className="sku"
            />
            <FormikControl
              control="input"
              type="text"
              label="Type"
              name="type"
              className="type"
            />
            <FormikControl
              control="select"
              // options={[{ key: "one", value: "one" }]}
              options={catArray}
              label="Category"
              name="category"
              className="category"
            />
            <FormikControl
              control="input"
              type="number"
              label="Price"
              name="price"
              className="price"
            />
            <FormikControl
              control="input"
              type="text"
              label="Title"
              name="title"
              className="title"
            />
            <FormikControl
              control="input"
              type="text"
              label="Prefab"
              name="prefab"
              className="prefab"
            />
            <FormikControl
              control="textarea"
              label="Description"
              name="description"
              className="description"
            />
            <FormikControl
              control="file"
              type="file"
              // disabled
              label="Thumbnail"
              name="files.thumbnail"
              className="thumbnail"
            />
            <FormikControl
              control="file"
              type="file"
              label="GLB File"
              name="files.glb_file"
              className="glb"
            />
            <FormikControl
              control="submitButton"
              type="submit"
              className="submitButton"
            />
            {/* <button type="submit" className="submit-button">
              Create DFX Model
            </button> */}
          </Form>
        )
      }}
    </Formik>
  )
}

export default CreateDFXModelForm
