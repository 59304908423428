import React from "react"
import Input from "./Input"
import TextArea from "./TextArea"
import Select from "./Select"
import RadioButton from "./RadioButton"
import Checkbox from "./Checkbox"
import DatePicker from "./DatePicker"
import FileUpload from "./FileUpload"
import * as styles from "../css/formik.module.css"

const FormikControl = props => {
  const { control, className, ...rest } = props
  switch (control) {
    case "input":
      return <Input {...rest} />

    case "textarea":
      return <TextArea {...rest} />

    case "select":
      return <Select {...rest} />

    case "radio":
      return <RadioButton {...rest} />

    case "checkbox":
      return <Checkbox {...rest} />

    case "date":
      return <DatePicker {...rest} />

    case "file":
      return <FileUpload {...rest} />

    case "submitButton":
      return (
        <button className={styles[`${className}`]} {...rest}> Create DFX Model </button>
      )

    default:
      return null
  }
}

export default FormikControl
