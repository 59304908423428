import { gql } from "@apollo/client"

export const AddCustomFieldToPostMutation = gql`
  mutation AddCustomFieldToPostMutation(
    $addCustomFieldToPostInput: AddCustomFieldToPostInput!
  ) {
    addCustomFieldToPost(input: $addCustomFieldToPostInput) {
      clientMutationId
      text
    }
  }
`

export const CreateDFXModelMutation = gql`
  mutation CreateDFXModelMutation(
    $createDfxModelInput: CreateDFXModelInput!
  ) {
    createDFXModel(input: $createDfxModelInput) {
      dFXModel {
        databaseId
      }
    }
  }
`


