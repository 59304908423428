import React from "react"
import TextError from "./TextError"
import { Field, ErrorMessage } from "formik"
import * as styles from "../css/formik.module.css"

const Input = props => {
  const { label, name, className, ...rest } = props
  return (
    <div className={styles[`${className}`]}>
      <label htmlFor={name}>
        {label}
      </label>
      <Field id={name} name={name} {...rest} className={styles.inputField} />
      <ErrorMessage name={name} component={TextError} />
    </div>
  )
}

export default Input
