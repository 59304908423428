import { gql } from "@apollo/client"

const getCategoriesQuery = gql`
  query getCategoriesQuery {
    categories {
      nodes {
        name
        databaseId
        parent {
          node {
            name
          }
        }
      }
    }
  }
`

export default getCategoriesQuery
