import React from "react"
import { Field, ErrorMessage } from "formik"
import TextError from "./TextError"
import * as styles from "../css/formik.module.css"

function Select(props) {
  const { label, name, options, className, ...rest } = props
  return (
    <div className={styles[`${className}`]}>
      <label htmlFor={name}>{label}</label>
      <Field
        as="select"
        id={name}
        name={name}
        {...rest}
        className={styles.inputField}
      >
        {options.map(option => {
          return (
            <option
              key={option.value}
              value={option.value}
              className={styles.categoryOptions}
            >
              {option.key}
            </option>
          )
        })}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  )
}

export default Select
