import React from "react"
import { Field, ErrorMessage } from "formik"
import TextError from "./TextError"
import * as styles from "../css/formik.module.css"

const FileUpload = props => {
  const { label, name, className, ...rest } = props
  return (
    <div className={styles[`${className}`]}>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <Field name={name} className={styles.inputField}>
        {({ form }) => {
          const { setFieldValue } = form
          return (
            <input
              id={name}
              {...rest}
              className={styles.fileInput}
              onChange={event => setFieldValue(name, event.target.files[0])}
            />
          )
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  )
}

export default FileUpload
