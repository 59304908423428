// extracted by mini-css-extract-plugin
export var formikContainer = "formik-module--formikContainer--vWCBf";
export var id = "formik-module--id--3Dhd_";
export var sku = "formik-module--sku--2rBC0";
export var type = "formik-module--type--3m1aF";
export var category = "formik-module--category--15dG7";
export var price = "formik-module--price--3jyGL";
export var title = "formik-module--title--2ZNBK";
export var prefab = "formik-module--prefab--mSAZN";
export var description = "formik-module--description--VzmkE";
export var thumbnail = "formik-module--thumbnail--EtPFH";
export var glb = "formik-module--glb--2lP-R";
export var inputField = "formik-module--inputField--3p_K8";
export var textArea = "formik-module--textArea--3j1qV";
export var label = "formik-module--label--1OqSz";
export var categoryOptions = "formik-module--categoryOptions--3qnh5";
export var submitButton = "formik-module--submitButton--1-_RU";
export var fileInput = "formik-module--fileInput--3Tw5y";